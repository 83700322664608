import React, {Component, lazy} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {mainLayoutComp} from "./hoc/MainLayoutComp";

const HomePage = mainLayoutComp(lazy(() => import('./routes/home/HomePage')));

export default class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path={"/"} component={HomePage}/>
                </Switch>
            </BrowserRouter>
        );
    }
}
