import {combineReducers} from "@reduxjs/toolkit";
import {homeSlice} from "./serviceModules";
import {enableMapSet} from "immer";

enableMapSet();

const rootReducer = combineReducers({
    home: homeSlice.reducer
})

export default rootReducer;