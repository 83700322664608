import React from "react";
import {connect} from "react-redux";

export function mainLayoutComp(Component) {
    class mainLayoutComponent extends React.Component {
        render() {
            return (
                <div>
                    <Component {...this.props}/>
                </div>
            );
        }
    }

    return connect()(mainLayoutComponent);
}
